.notfoundpage{
    position: fixed;
    top:0px;
    display: flex;
    flex-direction: column;
    width:100%;
    height:100vh;
    background-color: white;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    z-index:99;
}